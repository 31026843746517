<template>
  <div class="tab-wrap">
    <div
        @click="handleMove('left')"
        class="cell-l"
    >
        <font-icon
            class="icon"
            href="#icon-xiangzuo"
        ></font-icon>
    </div>
    <div class="cell-wrap">
        <ul :style="{'left': left+'px',}"
            class="wrap-inner"
            ref="wrapInner">
            <li
                :class="item.isActive ? 'tab-cell active' : 'tab-cell'"
                v-for="(item, index) in tabItems"
                :key="index"
                @click.prevent="handleTabItem(item, index)"
            >
                <!-- <span class="tab-text" :title="item.cnTitle + (item.enTitle != 'UserPermissionSet' && item.enTitle != 'BusinessClassificationSet' && item.enTitle != 'DepartmentPermissionSet' && item.enTitle != 'EnterpriseWechatSet' ? item.enTitle : '')">
                    <i>{{ item.cnTitle }}</i>
                    {{ item.enTitle != 'UserPermissionSet' && item.enTitle != 'BusinessClassificationSet' && item.enTitle != 'DepartmentPermissionSet' && item.enTitle != 'EnterpriseWechatSet' ? item.enTitle : '' }}
                </span> -->
                <span class="tab-text" :title="item.cnTitle">
                    <i>{{ item.cnTitle }}</i>
                </span>
                <span class="tab-close" @click="hanleCloseTab(index)">
                    <font-icon
                        class="tab-close-icon"
                        href="#icon-close-copy"
                    ></font-icon>
                </span>
                
                <!-- <span class="tab-close" @click.stop="hanleCloseTab(index)">
                    <i class="icon-font icon-close"></i>
                </span> -->
            </li>
        </ul>
    </div>
    
    <div
        @click="handleMove('right')"
        class="cell-r"
        >
        <font-icon href="#icon-xiangzuo-copy"></font-icon>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import tabArr from "#/js/config/workbenchTab.json";
export default {
  name: "workTableTab",
  data() {
    return {
        left: 0,
    };
  },
  mounted() {
    let curPath = this.$route.path;
    let tabItem = tabArr.find(item => item.path === curPath);
    if (this.checkIsCandidatesPage()) {
      tabItem = {
        "enTitle": "Talent",
        "cnTitle": "人才",
        "path": "/candidates/firmCandidate",
        "aliveComponent": "FirmCandidate"
      }
    }
    if(!tabItem){
        return;
    }
    tabItem.isActive = true;
    tabItem.lastScrollTop = 0;
    if (sessionStorage.getItem("tabItems")) {
      let tabItems = JSON.parse(sessionStorage.getItem("tabItems"));
      let _index;
      if(/floatingList/.test(curPath) && tabItems.findIndex(item => item.enTitle === 'Floating List') > -1) {
        _index = tabItems.findIndex(item => item.enTitle === 'Floating List');
        tabItems.splice(_index, 1, tabItem);
        this.invokeSetTabItems(tabItems);
        this.invokeSwitchTab(_index);
      }else if (this.checkIsCandidatesPage() && tabItems.findIndex(item => item.enTitle === 'Talent') > -1) {
        _index = tabItems.findIndex(item => item.enTitle === 'Talent');
        tabItems.splice(_index, 1, tabItem);
        this.invokeSetTabItems(tabItems);
        this.invokeSwitchTab(_index);
      } else {
        _index = tabItems.findIndex(item => item.path === curPath);
        if (_index > -1) {
          this.invokeSetTabItems(tabItems);
          this.invokeSwitchTab(_index);
        } else {
          tabItems.push(tabItem);
          this.invokeSetTabItems(tabItems);
          this.invokeSwitchTab(tabItems.length - 1);
        }
      }
    } else {
      this.invokeSetTabItems([tabItem]);
    }
  },
  computed: {
    ...mapGetters("workbench", {
      tabItems: "renderTabItems"
    })
  },
  watch: {
    tabItems(newVal, oldVal) {
        this.$nextTick(() => {
            this.checkInViewPort();
        });
    },
  },
  methods: {
    checkIsCandidatesPage() {
      let curPath = this.$route.path;
      if (curPath == '/candidates/firmCandidate' || curPath == '/candidates/personal' || curPath == '/candidates/namelist' || curPath == '/candidates/linkedin') {
        return true;
      } else {
        return false;
      }
    },
    handleMove (type) {
        console.log(this.$refs.wrapInner.parentNode.offsetWidth);
        const parentNodeWidth = this.$refs.wrapInner.parentNode.offsetWidth,
            innerNodeWidth = this.$refs.wrapInner.offsetWidth,
            between = parentNodeWidth - innerNodeWidth;

        if (between >= 0) return;

        if (type == 'left') {
            if (this.left >= between && this.left <= 0) {
                this.left += 50;
                if (this.left > 0) {
                    this.left = 0;
                }
            }
        } else {
            if (this.left >= between && this.left <= 0) {
                this.left -= 50;
                if (this.left < between) {
                    this.left = between;
                }
            }
        }
    },
    // 判断是否在视口中，如果不在就移入
    checkInViewPort() {
        const el = this.$refs.wrapInner?.getElementsByClassName('active')[0];
        const rect = el?.getBoundingClientRect() || {};
        console.log(`rect:`, rect)
        const parentRect = this.$refs.wrapInner?.parentNode.getBoundingClientRect();
        console.log(`parentRect:`, parentRect)
        const wrapInnerRect = this.$refs.wrapInner?.getBoundingClientRect();
        if ( rect.right < parentRect.left) {
            this.left = 0;
        } else if(rect.left > parentRect.width) {
            this.left = parentRect.width - wrapInnerRect.width;
        }
    },

    hanleCloseTab(index) {
      let aliveComponent = this.tabItems[index].aliveComponent;
      if(!aliveComponent) {
        aliveComponent = this.tabItems[index].enTitle.split(' ').join('');
      }
      this.invokeNoKeepAlive(aliveComponent);
      if(aliveComponent == 'FloatingListRecommendToMe' || aliveComponent == 'FloatingListMyRecommend' ) {
        this.invokeNoKeepAlive('FloatingListRecommendToMe');
        this.invokeNoKeepAlive('FloatingListMyRecommend');
      }
      if(aliveComponent == 'MyOrdersCreatedJob' || aliveComponent == 'MyOrdersGrabbedJob' || aliveComponent == 'MyOrdersSharedJob' || aliveComponent == 'MyOrdersA2aAuditJob' ) {
        this.invokeNoKeepAlive('MyOrdersCreatedJob');
        this.invokeNoKeepAlive('MyOrdersGrabbedJob');
        this.invokeNoKeepAlive('MyOrdersSharedJob');
        this.invokeNoKeepAlive('MyOrdersA2aAuditJob');
      }
      // if(aliveComponent == 'Member') {
      //   this.invokeNoKeepAlive('Member');
      // }
      // if(aliveComponent == 'JdtSet' || aliveComponent == 'JobSet' || aliveComponent == 'OrgAuditSet' || aliveComponent == 'MembersRoleSet' || aliveComponent == 'RolePermissionSet') {
      if(aliveComponent == 'MembersRoleSet' || aliveComponent == 'RolePermissionSet') {
        // this.invokeNoKeepAlive('JdtSet');
        // this.invokeNoKeepAlive('JobSet');
        // this.invokeNoKeepAlive('OrgAuditSet');
        this.invokeNoKeepAlive('MembersRoleSet');
        this.invokeNoKeepAlive('RolePermissionSet');
      }
      if(aliveComponent.indexOf('PointSetting') > -1) {
        this.invokeNoKeepAlive('PointSettingPrize');
        this.invokeNoKeepAlive('PointSettingSettleMent');
        this.invokeNoKeepAlive('PointSettingPoint');
      }

      this.tabItems.splice(index, 1);
      sessionStorage.setItem('tabItems',JSON.stringify(this.tabItems));
      if (this.tabItems.length == 0) {
        this.$router.push({ path: "/" });
      } else {
        this.tabItems.forEach(element => {
          element.isActive = false;
        });
        let tabItem = this.tabItems[index > 0 ? index - 1 : index];
        this.$router.push({ path: tabItem.path });
        tabItem.isActive = true;
      }
    },
    handleTabItem(tabItem, itemIndex) {
      let path = this.tabItems[itemIndex].path,pathname = '';
      if(path === this.$route.path){
          return false;
      }
      switch(path){
          case '/trackingList':
              pathname = 'TrackingList';
              break;
          case '/talentPool':
              pathname = 'TalentPool';
              break;
          case '/floatingList/myRecommend':
              pathname = 'FloatingList';
              break;
      }
      _tracker.track('PageViewCount', JSON.stringify({
          page: path,
          pageName: pathname,
          from: 'tab'
      }));

      if(document.querySelector('.el-table__body-wrapper')) {
        let lastScrollTop = document.querySelector('.el-table__body-wrapper').scrollTop;
        let lastIndex = this.tabItems.findIndex((tabItem) => {
          return tabItem.path === this.$route.path;
        });
        this.tabItems[lastIndex].lastScrollTop = lastScrollTop;
        this.invokeSetTabItems(this.tabItems);
      }
      
      this.$router.push({ path: path });
      this.invokeSwitchTab(itemIndex);
      this.$nextTick(() => {
        document.querySelector('.tab-item.active') && document.querySelector('.tab-item.active').scrollIntoView();
      })
    },
    ...mapActions({
      invokeSwitchTab: 'workbench/invokeSwitchTab',
      invokeSetTabItems: 'workbench/invokeSetTabItems',
      invokeNoKeepAlive: 'keepAlive/invokeNoKeepAlive'
    })
  }
};
</script>
<style lang="scss" scoped>
.tab-wrap {
//   background: #f5f5f5;
//     background: white;
//   padding-left: 30px;
//   padding-top: 10px;
//   padding-bottom: 2px;
//   border-bottom: 1px solid #f6f6f6;
//   box-sizing: border-box;
//   min-height: 42px;
    // width: calc(100% - 230px);
    height: 52px;
    background: white;
    display: flex;
    flex-flow: row nowrap;

    .cell-wrap {
        padding-top: 9px;
        flex: 1;
        // overflow-x: auto;
        overflow: hidden;
        max-width: calc(100% - 80px);
        position: relative;
        .wrap-inner {
            position: absolute;
            width: auto;
            white-space: nowrap;

            .tab-cell {
                display: inline-flex;
                align-items: center;
                height: 34px;
                background: white;
                border-radius: 5px;
                border: 1px solid #cccccc;
                margin-right: 10px;
                padding: 0 10px;
                user-select: none;
                &:first-child {
                    margin-left: 10px;
                }
                // border: 1px solid #38bc9d;
                .tab-text {
                    font-size: 14px;
                    color: #999;
                    max-width: 150px;
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .tab-close {
                    cursor: pointer;
                    margin-left: 10px;
                    display: inline-block;
                    // color: #ccc;
                    // fill: #999;
                    // fill: black;
                    vertical-align: top;
                    width: 14px;
                    height: 14px;
                    // border-radius: 50%;
                    // overflow: hidden;
                    // background: red;
                    // &:hover {
                    //     background: #f1fffc;
                    // }
                    .tab-close-icon{
                        fill: #999;
                        width: 14px;
                        height: 14px;
                        border-radius: 50%;
                        vertical-align: top;
                        &:hover{
                            background: #f1fffc;
                        }
                    }
                }
            }
            .active {
                border: 1px solid #38bc9d;
                background: #f1fffc;
                .tab-text {
                    color: #38bc9d;
                }
                .tab-close {
                    .tab-close-icon{
                        fill: #38bc9d;
                    }
                }
            }
        }
    }

  .tab-list {
    display: flex;
    overflow: hidden;
    overflow-x: auto;
    .tab-item {
      display: inline-block;
      position: relative;
      padding: 0 70px 0 20px;
      font-size: 18px;
      height: 32px;
      line-height: 31px;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      border-top: 1px solid transparent;
      cursor: pointer;
      .tab-text {
        > i {
          color: #444;
        }
      }
      &.active {
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-top: 2px solid #38bc9d;
        background: #F5F5F5;
        cursor: default;
        &:after {
          position: absolute;
          display: block;
          content: "";
          width: 100%;
          height: 2px;
          background: #f5f5f5;
          left: 0;
          bottom: -1px;
        }
        .tab-text {
          > i {
            font-weight: bold;
          }
        }
      }
      .tab-text {
        display: inline-block;
        max-width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: top;
        > i {
          margin-right: 10px;
        }
      }
      .tab-close {
        width: 20px;
        height: 20px;
        line-height: 16px;
        background: #fff;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        right: 20px;
        cursor: pointer;
        &:hover{
            background: #38BC9D;
            .icon-font{
                color: white;
            }
        }
        .icon-font {
          font-size: 12px;
          margin-top: -4px;
        }
      }
    }
  }


    .cell-l,.cell-r {
        width: 39px;
        height: 100%;
        line-height: 52px;
        text-align: center;
        cursor: pointer;
        &:hover{
            background: #f1fffc;
        }
        .icon {
            width: 14px;
            height: 16px;
            fill: #999;
            // background: blue;
        }
    }
    .cell-l {
        border-right: 1px solid rgba(235, 235, 235, 0.92);
    }
    .cell-r {
        border-left: 1px solid rgba(235, 235, 235, 0.92);
    }

}
</style>
