var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "notice-dialog",
      attrs: {
        title: "待处理候选人提醒",
        visible: _vm.dialogVisible,
        width: "420px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "notice-list" },
        _vm._l(_vm.noticeList, function (notice, index) {
          return _c("div", { key: index, staticClass: "notice-item" }, [
            _c("div", { staticClass: "notice-title text-item" }, [
              _c("span", { staticClass: "notice-time" }, [
                _vm._v(_vm._s(_vm._f("timeFilter")(notice.remindTime))),
              ]),
              _vm._v("，你有新的提醒。\n              "),
            ]),
            _c("div", { staticClass: "notice-candidate text-item" }, [
              _c("span", { staticClass: "notice-text" }, [_vm._v("候选人：")]),
              _c(
                "span",
                {
                  staticClass: "notice-realname text-primary",
                  on: {
                    click: function ($event) {
                      return _vm.goToCandidate(notice)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                      " +
                      _vm._s(notice.realName) +
                      "\n                  "
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "notice-content text-item" }, [
              _c("span", { staticClass: "notice-text" }, [
                _vm._v("提醒内容："),
              ]),
              _c("span", { staticClass: "content-text notice-text" }, [
                _vm._v(_vm._s(notice.content)),
              ]),
            ]),
          ])
        }),
        0
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmHandle } },
            [_vm._v("我知道了")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }