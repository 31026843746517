<template>
    <el-dialog
        :visible.sync="isShow"
        :title="isEdit ? '编辑沟通寻访' : '添加沟通寻访'"
        width="610px"
        class="inquiry-dialog"
        :show-close="false"
        v-loading="loading"
        :before-close="handleCancel"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <p v-if="isFromPhoneCall">为候选人：{{realName}} 添加沟通寻访</p>

        <div class="inquiry-dialog-tab">
            <label :class="validChecked ? 'radio-item selected' : 'radio-item'">
                <span class="radio-wrap"><input type="radio" class="radio-input" name="remark" @click="handleValid"/></span>
                <span>有效寻访</span>
            </label>
            
            <label v-if="!isEdit" :class="invalidChecked ? 'radio-item selected':'radio-item'">
                <span class="radio-wrap"><input type="radio" class="radio-input" name="remark" @click="handleInvalid"/></span>
                <span>无效寻访</span>
            </label>
        </div>

        <div class="inquiry-item">
            <label v-if="false" :class="validChecked ? 'radio-item selected' : 'radio-item'">
                <span class="radio-wrap"><input type="radio" class="radio-input" name="remark" @click="handleValid"/></span>
                <span>有效寻访</span>
                <el-checkbox class="checkbox-wrap" v-model="addToTrackingList" v-if="validChecked && !isTrackingList && !isEdit">将该简历添加至Tracking List方便后续沟通</el-checkbox>
            </label>
            <div class="common-inquiry" v-show="validChecked">
                <el-input
                    class="remark-text"
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 9}"
                    placeholder="添加一条新寻访记录"
                    v-model="inquiryContent"
                ></el-input>

                <el-checkbox class="checkbox-wrap" v-model="addToTrackingList" v-if="validChecked && !isTrackingList && !isEdit">将该简历添加至Tracking List方便后续沟通</el-checkbox>

                <div class="scroll-box">
                    <div class="candidate-info" 
                        v-if="!isFromAuthorization && (isFromPhoneCall || (!isEdit && isFirmResume && isCandidateDetail))">
                        <div class="title">
                            更新候选人的最新公司和职位
                            <!-- <span class="tip" v-if="$store.state.user.userInfo.isCFUser">（更新当前公司和职位可得15积分）</span> -->
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <customer-select-or-write
                                    size="mini"
                                    class="candidate-info-input"
                                    :class="!changeCompanyOrTitle ? 'no-checkbok' : ''"
                                    :customerId.sync="customerId"
                                    :customerName.sync="company"
                                    @finish-callback="finishCallback"
                                />
                            </el-col>
                            <el-col :span="12">
                                <el-input
                                    size="mini"
                                    class="candidate-info-input"
                                    :class="!changeCompanyOrTitle ? 'no-checkbok' : ''"
                                    v-model="title"
                                    placeholder="当前职位名称"
                                    :maxlength="128"
                                ></el-input>
                            </el-col>
                            
                        </el-row>
                        <el-row :gutter="20" style="margin-top: 10px;">
                            <el-col :span="12">
                                <div class="date-picker-pannel">
                                    <font-icon href="#icon-calendar"></font-icon>
                                    <div class="date-picker-wrap">
                                        <el-date-picker
                                            v-model="workStartDate"
                                            type="month"
                                            placeholder="开始时间"
                                            :picker-options="setExperienceStartDateOption()"
                                        >
                                        </el-date-picker>
                                    </div>
                                    <span class="date-picker-split">~</span>
                                    <div class="date-picker-wrap">
                                        <div class="date-tonow"
                                            v-if="workEndDate && workEndDate.toString().indexOf('9999')>-1">
                                            至今
                                        </div>
                                        <el-date-picker
                                            v-if="workEndDate && workEndDate.toString().indexOf('9999')>-1"
                                            v-model="workEndDate"
                                            type="month"
                                            placeholder="结束时间"
                                            :picker-options="setExperienceEndDateOption()"
                                            class="opacity-date-picker"
                                        ></el-date-picker>
                                        <el-date-picker
                                            v-if="!workEndDate || workEndDate.toString().indexOf('9999')===-1"
                                            v-model="workEndDate"
                                            type="month"
                                            placeholder="结束时间"
                                            :picker-options="setExperienceEndDateOption()"
                                        >
                                        </el-date-picker>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <customer-department-select
                                    v-if="customerId"
                                    ref="departmentSelect"
                                    size="mini"
                                    class="candidate-info-depart-select"
                                    :customerId="customerId"
                                    :departmentId="customerDepartmentId"
                                    :customerDepartmentName="customerDepartmentName"
                                    @update-department="updateDepartment"
                                    placeholder='输入完整路径，多级部门用"，"分隔'
                                ></customer-department-select>
                            </el-col>
                        </el-row>
                        <el-checkbox
                            class="checkbox-item"
                            v-model="isAddWork"
                            v-if="changeCompanyOrTitle"
                        >
                            添加为最新的工作经历
                            <i class="add-work-tip">(新增一段最近工作经历时，请记得勾选)</i>
                        </el-checkbox>
                    </div>

                    <div class="select-block">
                        <div class="select-block-title">
                            <font-icon class="active time-icon" href="#icon-time-active"></font-icon>
                            跳槽时机
                        </div>

                        <ul class="inquiry-select-list">
                            <li :class="item.selected ? 'select-item selected' : 'select-item'" v-for="(item, index) in opportunityArr" :key="index" @click="handleItem(item, opportunityArr, 'opportunitySelected')">{{item.text}}</li>
                        </ul>
                    </div>
                    <div class="select-block">
                        <div class="select-block-title">
                            <font-icon class="active" href="#icon-building-active"></font-icon>
                            雇主画像
                        </div>

                        <ul class="inquiry-select-list">
                            <li :class="item.selected ? 'select-item selected' : 'select-item'" v-for="(item, index) in employerArr" :key="index" @click="handleItem(item, employerArr, 'employerSelected')">{{item.text}}</li>
                        </ul>
                    </div>
                    <div class="select-block">
                        <div class="select-block-title">
                            <font-icon class="active" href="#icon-salary-active"></font-icon>
                            期望报酬
                        </div>

                        <ul class="inquiry-select-list">
                            <li :class="item.selected ? 'select-item selected':'select-item'" v-for="(item, index) in rewardArr" :key="index" @click="handleItem(item, rewardArr, 'rewardSelected')">{{item.text}}</li>
                            <li :class="isCustom ? 'select-item selected':'select-item'" v-show="!isInput" @click="handleItem({id:0, text:''}, rewardArr, 'rewardSelected')">{{customInput ? customInput : '自定义'}}</li>
                            <input ref="customInput" v-show="isInput" type="text" class="custom-input" maxLength="36" v-model="customInput" @blur="handleBlur"/>
                        </ul>
                    </div>
                    
                    <div class="hide-switch">
                        <span class="hide-switch-text">是否隐藏</span>
                        <el-switch
                            v-model="isHide"
                            :width="40"
                            active-color="#38bc9d"
                            inactive-color="#999999"
                            @change="handleHideStatus"
                        ></el-switch>
                    </div>
                </div>
            </div>
        </div>
        <div class="inquiry-item" v-if="!isEdit">
            <label v-if="false" :class="invalidChecked ? 'radio-item selected':'radio-item'">
                <span class="radio-wrap"><input type="radio" class="radio-input" name="remark" @click="handleInvalid"/></span>
                <span>无效寻访</span>
            </label>
            <ul class="invalid-list" v-show="invalidChecked">
                <li :class="item.selected ? 'li-item selected' : 'li-item'" v-for="(item, index) in invalidArr" :key="index" @click="handleItem(item, invalidArr, 'invalidText')">
                    <span>
                        {{item.text}}<i class="remark" v-if="item.remark">（{{item.remark}}）</i>
                    </span>
                    <i class="el-icon-check cancel-selected-icon" @click.stop="cancelSelected" v-if="item.selected"></i>
                </li>
            </ul>
            <div class="hide-switch" v-show="invalidChecked">
                <span class="hide-switch-text">是否隐藏</span>
                <el-switch
                    v-model="isHide"
                    :width="40"
                    active-color="#38bc9d"
                    inactive-color="#999999"
                    @change="handleHideStatus"
                ></el-switch>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import moment from 'moment';
    import talentPoolService from '@src/js/service/talentPoolService.js';
    import phoneService from '@src/js/service/phoneService.js';
    import CustomerDepartmentSelect from '#/component/common/customer-department-select.vue'
    import CustomerSelectOrWrite from '#/component/common/customer-select-or-write.vue';

    //跳槽时机
    const opportunityList = [{
        id:1,
        text:'不考虑机会'
    }, {
        id:2,
        text:'6个月内不考虑'
    }, {
        id:3,
        text:'12个月内不考虑'
    }, {
        id:4,
        text:'刚入职新公司'
    }, {
        id:5,
        text:'看职业机会'
    }, {
        id:6,
        text:'离职状态'
    }, {
        id:7,
        text:'该公司最近有裁员传闻/新闻'
    }]
    //雇主画像
    const employerList = [{
        id:1,
        text:'上市公司'
    }, {
        id:2,
        text:'创业早期(天使/A/B/C轮)'
    }, {
        id:3,
        text:'创业中后期(D/E/F轮)'
    }, {
        id:4,
        text:'独角兽/行业巨头'
    }, {
        id:5,
        text:'都可以'
    }, {
        id:6,
        text:'外资企业/跨国企业'
    },{
        id:7,
        text:'知名成熟公司'
    }]
    //期望报酬
    const rewardList = [{
        id: 1,
        text:'20-30W'
    }, {
        id: 2,
        text:'30-50W'
    }, {
        id: 3,
        text:'50-80W'
    }, {
        id: 4,
        text:'80-100W'
    }, {
        id:5,
        text:'100-150W'
    }, {
        id: 6,
        text:'150-200W'
    }, {
        id: 7,
        text:'200-300W'
    }, {
        id: 8,
        text:'300W以上'
    }];
    // 无效寻访
    const invalidList = [{
    //     id:1,
    //     text:'有效通话'
    // }, {
        id:2,
        text:'未接电话'
    }, {
        id:3,
        text:'无效电话',
        remark:'空号/号码非本人'
    }, {
        id:4,
        text:'电话打不通',
        remark:'关机/停机/无法接通'
    }, {
        id:5,
        text:'暂不方便接听'
    }, {
        id:6,
        text:'拒绝与猎头沟通'
    }];
    export default {
        name: "inquiry-dialog",
        components: {
            CustomerDepartmentSelect,
            CustomerSelectOrWrite,
        },
        props:{
            isTrackingList: false,

            // 简历详情传5，talentpool传6
            sourceFrom:Number,
        },
        mounted() {
            window.moment = moment;
        },
        computed:{
            token(){
                return this.$store.state.verificationToken.verificationToken;
            },
            changeCompanyOrTitle() {
                return this.company !== this.recentCompany 
                    || this.title !== this.recentTitle
                    || moment(this.workStartDate).format('YYYY-MM-DD') != moment(this.recentWorkStartDate).format('YYYY-MM-DD')
                    || moment(this.workEndDate).format('YYYY-MM-DD') != moment(this.recentWorkEndDate).format('YYYY-MM-DD');
            },
            isCandidateDetail() {
                // return (location.href.indexOf('/candidateDetail/') > -1 && location.href.indexOf('/Headhunting/MyCompany.html') > -1) || location.href.indexOf('/talentPool') > -1 || location.href.indexOf('/trackingList') > -1;
                return location.href.indexOf('/candidateDetail/') > -1 || location.href.indexOf('/talentPool') > -1 || location.href.indexOf('/trackingList') > -1;
            }
        },
        data() {
            return {
                isShow: false,
                resume: {},
                selectArr: [],
                opportunityArr: [],
                employerArr: [],
                rewardArr: [],
                invalidArr: [],
                validChecked: false,
                invalidChecked: false,
                isInput: false,
                isCustom: false,
                addToTrackingList: false,
                inquiryContent: '',
                opportunitySelected: '',
                employerSelected: '',
                rewardSelected: '',
                customInput: '',
                invalidText: '',
                loading: false,
                isEdit:false,
                inquiryId:'',
                recentCompany: "",
                recentTitle: "",
                recentWorkStartDate: '',
                recentWorkEndDate: '',
                company: "",
                title: "",
                workStartDate: '',
                workEndDate: '',
                workExperience: {},
                isAddWork: false,
                isFirmResume: true,
                isFromPhoneCall: false,
                phoneCallRecordId: '',
                phoneCallResumeId: '',
                realName: '',
                isFromAuthorization: false,
                hideStatus: 1,
                isHide: false,

                customerId: null,
                customerDepartmentId: null,
                customerDepartmentName: '',
            }
        },
        created() {
            opportunityList.map((item)=>{
                item.selected = false;
                this.opportunityArr.push(item);
            })
            employerList.map((item)=>{
                item.selected = false;
                this.employerArr.push(item);
            })
            rewardList.map((item)=>{
                item.selected = false;
                this.rewardArr.push(item);
            })
            invalidList.map((item)=>{
                item.selected = false;
                this.invalidArr.push(item)
            })
        },
        methods: {
            updateDepartment(department = {}) {
                this.customerDepartmentId = department.id;
                this.customerDepartmentName = department.departmentName;
            },
            finishCallback () {
                this.customerDepartmentName = null;
                this.$nextTick(() => {
                    this.$refs.departmentSelect && this.$refs.departmentSelect.reset();
                });
            },
            show(json){
                this.isEdit = false;
                this.resume = json;
                this.isShow = true;
                this.isFromPhoneCall = json.isFromPhoneCall || false;
                this.isFromAuthorization = json.resumeListItem ? json.resumeListItem.isFromAuthorization : json.isFromAuthorization;
                this.phoneCallRecordId = json.recordId || '';
                this.phoneCallResumeId = json.resumeId || '';
                this.realName = json.realName || '未知';
                this.isFirmResume = json.resumeListItem ? json.resumeListItem.isFirmResume : json.isFirmResume;
                this.recentCompany = this.company = json.resumeListItem ? json.resumeListItem.company : json.company;
                this.recentTitle = this.title = json.resumeListItem ? json.resumeListItem.title : json.title;
                let workExperiences = json.resumeListItem ? json.resumeListItem.workExperiences : json.workExperiences;
                this.workExperience = workExperiences && workExperiences.length ? workExperiences[0] : {};
                this.recentWorkStartDate = this.workStartDate = this.workExperience.startDate || '';
                this.recentWorkEndDate = this.workEndDate = this.workExperience.endDate || '';

                this.customerId = json.customerId || null;
                if(this.customerId == '0') {
                    this.customerId = null;
                }
                this.customerDepartmentId = json.customerDepartmentId;
                this.customerDepartmentName = json.customerDepartmentName;

                this.$nextTick(() => {
                    if(this.$refs.departmentSelect && this.customerDepartmentId){
                        this.$refs.departmentSelect.value = this.customerDepartmentId;
                        this.$refs.departmentSelect.editInit({
                            id: this.customerDepartmentId
                        });
                    }
                });
            },
            handleItem(option, itemArr, selectedKey){
                let selectedText = option.text;
                if(selectedKey == 'invalidText') {
                    this.invalidArr.forEach(item => {
                        item.selected = false;    
                    })
                    option.selected = true;
                    return;
                }
                itemArr.map((item)=>{
                    if(item.text == selectedText){
                        item.selected = true;
                        this[selectedKey] = selectedText;
                    }else{
                        item.selected = false;
                    }
                })
                if(selectedKey == 'rewardSelected' && this.isCustom) {
                    //用户自定义输入之后又选择了提供的期望报酬时，取最新的用户操作
                    this.isCustom = false;
                }
                if(!option.id){
                    //自定义输入
                    this.isInput = true;
                    this.$nextTick(function(){
                        this.$refs.customInput.focus();
                    })
                }
            },
            handleValid(){
                this.validChecked = true;
                this.invalidChecked = false;
                this.handleResetInvalid();
            },
            handleInvalid(){
                this.invalidChecked = true;
                this.validChecked = false;
                this.handleResetValid();
            },
            handleBlur(){
                this.isInput = false;
                this.isCustom = true;
            },
            handleResetInvalid(){
                let items = this.invalidArr;
                items.map((item)=>{
                    item.selected = false;
                })
                this.invalidText = '';
                this.isHide = false;
                this.hideStatus = 1;
                this.invalidChecked = false;
            },
            handleResetValid(){
                this.opportunityArr.map((item)=>{
                    item.selected = false;
                })
                this.employerArr.map((item)=>{
                    item.selected = false;
                })
                this.rewardArr.map((item)=>{
                    item.selected = false;
                })
                this.cancelSelected();
                this.validChecked = false;
                this.addToTrackingList = false;
                this.inquiryContent = '';
                this.opportunitySelected = '';
                this.employerSelected = '';
                this.rewardSelected = '';
                this.isCustom = false;
                this.customInput = '';
                this.isHide = false;
                this.hideStatus = 1;
                if(!this.isShow) {
                    this.company = "";
                    this.title = "";
                } else {
                    this.company = this.recentCompany;
                    this.title = this.recentTitle;
                }
                this.isAddWork = false;
            },
            cancelSelected() {
                this.invalidArr = [];
                invalidList.map((item)=>{
                    item.selected = false;
                    this.invalidArr.push(item)
                })
                this.invalidText = '';
            },
            getInvalidText() {
                let text = '';
                this.invalidArr.some(item => {
                    if (item.selected) {
                        text = item.text;
                        return true;
                    }
                })
                return text;
            },
            handleConfirm(){
                let content = this.invalidChecked ? this.getInvalidText() : this.inquiryContent;

                if(!content || !content.trim()){
                    return shortTips('请填写寻访记录');
                } else if(content.length > 800) {
                    return shortTips('寻访内容最长支持800个字符');
                }
                if(this.company && this.company.length < 2) {
                    return shortTips('公司名称不能少于2个字符');
                }
                if(this.company && this.company.length > 128) {
                    return shortTips('公司名称最长支持128个字符');
                }
                if(this.title && this.title.length < 2) {
                    return shortTips('职位名称不能少于2个字符');
                }
                if(this.title && this.title.length > 128) {
                    return shortTips('职位名称最长支持128个字符');
                }
                if (!this.isEdit) {
                    if(this.workStartDate && this.workEndDate) {
                        if (this.workEndDate.getFullYear() == '9999') {
                            if (moment() - moment(this.workStartDate) > 45*365*24*60*60*1000) {
                                return shortTips("请输入真实的工作经历时间");
                            }
                        } else {
                            if (moment(this.workEndDate) - moment(this.workStartDate) > 45*365*24*60*60*1000) {
                                return shortTips("请输入真实的工作经历时间");
                            }
                        }
                    } else {
                        return shortTips('请选择工作时间');
                    }
                }
                if(this.customerDepartmentName && this.title.customerDepartmentName > 128) {
                    return shortTips('职位部门名称最长支持128个字符');
                }

                let dataJson = {
                    isValidInquiry: this.validChecked,
                    content: content,
                    IsAddTrackingList: false,
                    hideStatus: this.hideStatus
                };
                if(this.validChecked){
                    dataJson.IsAddTrackingList = (this.isTrackingList||this.isEdit) ? false : this.addToTrackingList;
                    dataJson.content = content;
                    dataJson.jobHoppingOccasion = this.opportunitySelected;
                    dataJson.employerInfo = this.employerSelected;
                    if(dataJson.IsAddTrackingList){
                        dataJson.trackingListSourceFrom=this.sourceFrom;
                    }
                    if(this.isCustom){
                        dataJson.expectSalary = this.customInput;
                    }else{
                        dataJson.expectSalary = this.rewardSelected
                    }
                    if(this.isFirmResume && !this.isEdit && !(this.company && this.title)) {
                        return shortTips('公司和职位不能为空');
                    }
                }else if(!this.invalidChecked){
                    shortTips('请选择寻访选项');
                }
                this.loading = true;
                if(this.isEdit) {
                    talentPoolService.updateInquiryToResume(this.resume.resumeId,this.inquiryId,this.token,dataJson).then((res) => {
                        this.loading = false;
                        this.$emit('update-item', this.resume);
                        this.handleCancel();
                    }).catch((err) => {
                        this.loading = false;
                        this.isShow = false;
                        console.log(err);
                    });
                } else {
                    let updateWorkExp = false, addInquiry = false;
                    if(this.changeCompanyOrTitle && this.isFirmResume && (this.isCandidateDetail || this.isFromPhoneCall) && this.validChecked) {
                        let url = '',
                            id = this.resume.resumeListItem ? this.resume.resumeListItem.candidateId : this.resume.candidateId;
                        let params = {
                            id: id,
                            type: 1,
                            data: {
                                workExperience: {
                                    company: this.company,
                                    title: this.title,
                                    isLatestWorkExperience: true,
                                    location: 0,
                                    description: "",
                                    startDate: this.workStartDate,
                                    endDate: this.workEndDate,
                                    id: ""
                                }
                            }
                        };
                        if(Object.keys(this.workExperience).length!=0 && !this.workExperience.company) {
                            url = "/My/Candidate/%p/ResumeItem/Add";
                            params.data.workExperience['customerDepartmentId'] = this.customerDepartmentId || 0;
                            params.data.workExperience['customerDepartmentName'] = this.customerDepartmentName;
                            params.data.workExperience['customerId'] = this.customerId || null;
                        } else if (this.isAddWork) {
                            url = "/My/Candidate/%p/ResumeItem/Add";
                            params.data.workExperience['customerDepartmentId'] = this.customerDepartmentId || 0;
                            params.data.workExperience['customerDepartmentName'] = this.customerDepartmentName;
                            params.data.workExperience['customerId'] = this.customerId || null;
                        }else {
                            url = "/My/Candidate/%p/ResumeItem/Edit";
                            Object.keys(params.data.workExperience).forEach(item => {
                                if(item != 'company' && item != 'title' && item != 'startDate' && item != 'endDate') {
                                    params.data.workExperience[item] = this.workExperience[item];
                                }
                            });
                            params.data.workExperience['customerDepartmentId'] = this.customerDepartmentId || 0;
                            params.data.workExperience['customerDepartmentName'] = this.customerDepartmentName;
                            params.data.workExperience['customerId'] = this.customerId || null;
                        }
                        _request({
                            url: url.replace(/\%p/ig, id),
                            method: "POST",
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded"
                            },
                            data: params
                        }).then(res => {
                            updateWorkExp = true;
                            if(updateWorkExp && addInquiry) {
                                this.addInquiryEnd();
                                this.$emit('refresh-detail', this.changeCompanyOrTitle);
                            }
                        }).catch(err => {
                            updateWorkExp = true;
                            if(addInquiry) {
                                this.loading = false;
                                this.isShow = false;
                            }
                            console.log(err)
                        })
                    } else {
                        updateWorkExp = true;
                    }

                    if(this.isFromPhoneCall) {
                        dataJson.Remark = dataJson.content;
                        delete dataJson.content;
                        dataJson.IsAddTracking = dataJson.IsAddTrackingList;
                        delete dataJson.IsAddTrackingList;
                        delete dataJson.isValidInquiry;

                        dataJson.ResumeId = this.phoneCallResumeId,
                        dataJson.RecordId = this.phoneCallRecordId;
                        dataJson.RemarkType = this.invalidChecked ? this.invalidArr[0].id : 1;
                        phoneService.addInquiry(dataJson)
                            .then(res =>{
                                shortTips('添加成功！');
                                this.$emit('phone-call-inquiry-added');
                                this.handleCancel();
                            }).catch(err =>{
                                this.loading = false;
                            })
                    }else {
                        // 寻访增加部门参数
                        dataJson.customerDepartmentId = this.customerDepartmentId || 0,
                        dataJson.customerDepartmentName = this.customerDepartmentName;
                        dataJson.customerId = this.customerId || null;

                        talentPoolService.addInquiryToResume(this.resume.resumeListItem ? this.resume.resumeListItem.resumeId : this.resume.resumeId , dataJson).then((res) => {
                            addInquiry = true;
                            if(updateWorkExp && addInquiry) {
                                this.addInquiryEnd();
                                if(this.validChecked && this.changeCompanyOrTitle) {
                                    this.$emit('refresh-detail', this.changeCompanyOrTitle);
                                }
                            }
                        }).catch((err) => {
                            addInquiry = true;
                            if(updateWorkExp) {
                                this.loading = false;
                                this.isShow = false;
                            }
                            console.log(err);
                        });
                    }

                }
            },
            handleCancel(){
                this.isShow = false;
                this.loading = false;
                this.handleResetValid();
                this.handleResetInvalid();
                this.$emit('dialogClosed');
            },
            edit(val){
                this.isShow = true;
                this.validChecked = true;
                this.inquiryContent = val.content;
                let isSalaryCustom = true;
                this.resume.resumeId = val.resumeId;
                this.inquiryId = val.id;
                this.isHide = val.hideStatus !== undefined ? (val.hideStatus === 1 ? false : true) : false;
                this.hideStatus = val.hideStatus !== undefined ? val.hideStatus : 1;
                this.isEdit = true;
                if(val.employerInfo){
                    this.employerSelected = val.employerInfo;
                    this.employerArr.forEach((item)=>{
                        if(item.text == val.employerInfo){
                            item.selected = true;
                        }
                    })
                }
                if(val.jobHoppingOccasion){
                    this.opportunitySelected = val.jobHoppingOccasion;
                    this.opportunityArr.forEach((item)=>{
                        if(item.text == val.jobHoppingOccasion){
                            item.selected = true;
                        }
                    })
                }
                if(val.expectSalary){
                    this.rewardArr.forEach((item)=>{
                        if(item.text == val.expectSalary){
                            item.selected = true;
                            isSalaryCustom = false;
                        }
                    })
                    if(isSalaryCustom){
                        this.isCustom = true;
                        this.customInput = val.expectSalary;
                    }else{
                        this.rewardSelected = val.expectSalary;
                    }
                }
            },
            addInquiryEnd() {
                this.loading = false;
                this.$emit('update-item', this.resume);
                if(this.addToTrackingList) {
                    _tracker.track('addTrackingList', JSON.stringify(
                        {
                            candidateId: this.resume.candidateId,
                            resumeId: this.resume.resumeId,
                            from: 'inquiryDialog'
                        }
                    ))
                    if(this.isCandidateDetail) {
                        this.$parent.candidateDetailData.isAddToTrackingList = true;
                    }
                }
                this.handleCancel();
            },
            handleHideStatus(value) {
                if(value) {
                    this.hideStatus = 0;
                } else {
                    this.hideStatus = 1;
                }
            },
            //日期控件配置
            setExperienceStartDateOption(){
                if(this.workEndDate != '' && typeof this.workEndDate==='string'){
                    this.workEndDate = new Date(this.workEndDate.split('T')[0]);
                }
                return {
                    disabledDate: (time) => {
                        if (!!this.workEndDate) {
                            return time.getTime() > Date.now() || time.getTime() > this.workEndDate.getTime();
                        } else {
                            return time.getTime() > Date.now();
                        }
                    }
                }
            },
            setExperienceEndDateOption(){
                if(this.workStartDate != '' && typeof this.workStartDate==='string'){
                    this.workStartDate = new Date(this.workStartDate.split('T')[0]);
                }
                let _this = this;
                return {
                    disabledDate: (time) => {
                        if (!!this.workStartDate) {
                            return time.getTime() < this.workStartDate.getTime() || time.getTime() > Date.now();
                        }
                    },
                    shortcuts: [{
                        text: '至今',
                        onClick(picker) {
                            let date = new Date('9999-12-31');
                            picker.$emit('pick', date);
                        }
                    },{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }]
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
.inquiry-dialog-tab {
    display: flex;
    align-items: center;
    gap: 46px;

    .radio-wrap{
        display: inline-block;
        position: relative;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        vertical-align: middle;
        & + span{
            margin-left: 5px;
        }
    }
    .radio-input{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
    }
    .radio-item{
        color:#444;
        cursor: pointer;
        line-height: 26px;
        margin-bottom: 0;
        .radio-wrap{
            border: 1px solid #D3D3D3;
        }
        &.selected{
            color:#444;

            .radio-wrap{
                border-color: $primary;

                &:after{
                    content: '';
                    display: block;
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 8px;
                    height: 8px;
                    background-color: $primary;
                    border-radius: 50%;
                }
            }

            .remark{
                color: #37BC9C;
            }
        }
        > span{
            vertical-align: middle;
        }
        .remark{
            color: #999;
        }
    }
}
.inquiry-item{
    font-size: 14px;
    color: #666;

    &.tracking-list-checkbox {
        padding: 0;
        text-align: center;
        height: 34px;
        line-height: 34px;
        background-color: #ebf8f5;
    }
}

.common-inquiry {
    width: 100%;
    // max-height: 318px;
    line-height: initial;
    padding-top: 4px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    .scroll-box {
        flex-grow: 1;
        // overflow: auto;
    }
    .candidate-info {
        .title {
            font-size: 14px;
            color: #333;
            line-height: 20px;
            margin-bottom: 8px;
            .tip {
                font-size: 12px;
                color: #fc7859;
            }
        }

        .el-row {
            height: 28px;
        }

        .date-picker-pannel{
            display: flex;
            align-items: center;
            width: 100%;
            border: 1px solid #DCDFE6;
            border-radius: 4px;
            overflow: hidden;
            padding-left: 10px;

            ::v-deep .el-input {
                width: 100%;

                .el-input__inner{
                    border: none;
                }
            }
        }

        ::v-deep .date-picker-wrap{
            display: inline-block;
            width: 100%;
            position: relative;
            .el-date-editor{
                .el-input__prefix{
                    display: none;
                }
                .el-input__inner{
                    text-align: center;
                    height: 26px;
                    line-height: 26px;
                    font-size: 12px;

                    &:hover, &:focus {
                        border: none;
                    }
                }

                .el-input__suffix .el-input__icon {
                    line-height: 28px;
                }
            }
            .el-input--prefix .el-input__inner{
                padding-left: 10px;
            }
            .el-input--suffix .el-input__inner{
                padding-right: 10px;
            }

            .opacity-date-picker{
                .el-input__inner{
                    opacity: 0;
                    z-index: 2;
                }
            }

            .date-tonow{
                position: absolute;
                line-height: 12px;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: #fff;
                line-height: 28px;
                text-align: center;
                font-size: 12px;
            }

        }

        .candidate-info-depart-select {
            height: 28px;
            
            div.department-select-input {
                height: 28px;
                line-height: 28px;
            }
        }
    }
}
.invalid-list{
    width: 100%;
    margin-top: 16px;
    border: 1px solid #eee;
    border-radius: 4px;
    .li-item{
        height: 40px;
        padding: 0 16px;
        line-height: 40px;
        &:hover{
            color: $primary;
            background:rgba(56,188,157,.2);
        }
        &.selected{
            color: $primary;
        }
        & > span:nth-of-type(1) {
            cursor: pointer;
        }
        .cancel-selected-icon {
            float: right;
            font-size: 18px;
            line-height: 40px;
            cursor: pointer;
        }
    }
}

.select-block {
    
    &-title {
        display: flex;
        align-items: center;
        gap: 6px;
        height: 22px;
        margin: 12px 0;
        font-size: 16px;
        color: $primary;

        .font-icon {
            font-size: 20px;
        }
    }

}

.inquiry-select-list{
    display: flex;
    gap: 10px;
    color: rgba(53,64,82,.5);
    flex-wrap: wrap;
    margin-bottom: 0;
    .select-item{
        display: inline-block;
        padding: 4px 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        cursor: pointer;
        &:hover, &.selected{
            color: #38BC9D;
            border: 1px solid #38BC9D;
        }
    }
    .custom-input{
        width: 60px;
        height: 29px;
        line-height: 29px;
        border: 1px solid #E6EAEE;
        border-radius: 4px;
        padding: 0 8px;
    }
}
.hide-switch {
    margin: 8px 0 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &-text {
        font-size: 14px;
        color: #666;
        margin-right: 10px;
    }
    /deep/ .el-switch {
        &__core:before {
            content: '关';
            position: absolute;
            top: 1px;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            left: 100%;
            margin-left: -18px;
            font-size: 12px;
            color: #fff;
        }

        &.is-checked {
            .el-switch__core:before {
                content: '开';
                left: 1px;
                margin-left: 0;
            }
        }
    }
}
</style>
<style lang="scss">
.inquiry-dialog {
    .el-dialog__body {
        padding: 30px 0;
    }
}
.el-checkbox.checkbox-wrap {
    display: flex;
    align-items: center;
    float: right;
    margin-bottom: 10px;

    .el-checkbox__inner {
        width: 18px;
        height: 18px;
        vertical-align: top;
    }
    .el-checkbox__label{
        padding-left: 6px;
        color: #444;
    }
    .el-checkbox__input.is-checked {
        .el-checkbox__inner{
            background-color: #fff;
            &::after {
                height: 10px;
                left: 6px;
                border: 2px solid $primary;
                border-left: none;
                border-top: none;
            }
        }
    }
    &.is-checked .el-checkbox__label{
        color: #444;
    }
}
.remark-text.el-textarea{
    .el-textarea__inner {
        display: block;
        background: #FFFFFF;
        border: 1px solid #ddd;
        border-radius: 2px;
        width:100%;
        min-height: 72px;
        max-height: 272px;
        padding: 5px 10px;
        font-size: 14px;
        margin-bottom: 6px;
        &::placeholder{
            color: #aaa;
        }
        &:focus {
            border-color: #7BCCBB;
        }
    }
}
.candidate-info-input.el-input {
    &.no-checkbok {
        margin-bottom: 10px;
    }
    .el-input__inner {
        width: 100%;
        border: 1px solid #ddd;
        padding: 0 10px;
    }
    &:nth-first-child{
        margin-right: 20px;
    }
    // &:nth-child(1){
    //     margin-right: 20px;
    // }
}

.checkbox-item {
    margin-top: 5px;
    margin-bottom: -5px;
    line-height: 28px;
    height: 28px;
    .el-checkbox__label {
        font-size: 12px;
    }
}
.inquiry-tabs.el-tabs {
    .el-tabs__header {
        .el-tabs__nav-wrap {
            margin-bottom: 0;
            overflow: visible;
            &::after {
                display: none;
            }
        }
        .el-tabs__nav-scroll {
            overflow: visible;
        }
        .el-tabs__nav {
            position: relative;
            width: 378px;
            float: none;
            overflow: visible !important;
        }
        .el-tabs__active-bar {
            display: none;
        }
        .el-tabs__item {
            border-bottom: 1px solid #eee;
            height: 36px;
            line-height: 36px;
            width: 120px;
            text-align: center;
            color: #999;
            position: relative;
            padding: 0;
            &::before {
                content: "";
                position: absolute;
                display: inline-block;
                width: 1px;
                height: 14px;
                background-color: #eee;
                top: calc(50% - 7px);
                left: 0;
            }
            .default, .active {
                width: 14px;
                height: 14px;
                margin-top: -2px;
            }
            .time-icon {
                width: 12px;
                height: 12px;
                margin-top: -3px;
            }
            .default {
                display: inline-block;
            }
            .active {
                display: none;
            }
            &:nth-child(2) {
                &::before {
                    display: none;
                }
            }
            &.is-active {
                color: $primary;
                background-color: #eee;
                &::before {
                    display: none;
                }
                & + .el-tabs__item {
                    &::before {
                        display: none;
                    }
                }
                &::after {
                    content: "";
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    background-color: #eee;
                    transform: rotate(45deg);
                    position: absolute;
                    left: calc(50% - 4px);
                    bottom: -4px;
                }
                .active {
                    display: inline-block;
                }
                .default {
                    display: none;
                }
            }
        }
    }
}
</style>
